import logo from './assets/logo.png';
import service from './assets/service.png';

import './App.css';
import { motion, AnimatePresence } from "framer-motion";

const visible = { opacity: 1, y: 0, x: 0, transition: { duration: 1 } };

const variants2 = {
  hidden: { opacity: 0, y: 80 },
  visible,
};

const variants3 = {
  hidden: { opacity: 0, y: 80 },
  visible,
};

function App() {
  return (
    <div className="app container">
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        >
          <motion.header variants={{
            hidden: { opacity: 0, y: -40 },
            visible
          }}>
            <img src={logo} alt='alchemus-logo' width={"160px"} />
          </motion.header>

          <div className='row'>
            <motion.div variants={variants2} className="titleDescription col-md-12 col-lg-6 col-sm-12">
                <h1 className='title'>Elevate Your Online Presence with Alchemus.</h1>

                <p className='pt-3 description'>At Alchemus Web Design, we specialize in more than just creating websites; we craft digital experiences that leave a lasting impact. Elevate your online presence with us and unlock the full potential of your brand on the web. Our team of creative minds and technical experts collaborate to design and develop websites that not only look stunning but also function flawlessly. Whether you're a small startup or a well-established business, we tailor our solutions to your unique needs. Don't just exist online; thrive and stand out. Give us a try today, and let's transform your digital presence into something truly extraordinary. Your success is our passion.</p>
            
                <p className='fw-bold mt-3 lead contact-us'>Contact us at (502) 619-4555 for more information.</p>
            </motion.div>

            <motion.div variants={variants3} className="titleDescription col-md-12 col-lg-6 col-sm-12">
              <img src={service} alt='service' width={"100%"} />
            </motion.div>
          </div>

        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default App;
